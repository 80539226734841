.main-pc {
  /* width: 365px; */
  height: 380px;
  margin: 5;
  border: 1px #cccccc solid;
  border-radius: 8px;
}

.pc-header-inside {
  padding: 8px 10px;
  line-height: 12px;
  text-align: left;
  height: 45px;
}

.pc-header-inside-uname {
  font-size: 12px;
  margin-top: 10px;
}

.pc-header-inside-followers {
  font-size: 8px;
  color: #70706c;
}

.pc-header-dotted-menu {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 10px;
  opacity: 0.7;
}

.pc-header-dotted-menu:hover {
  opacity: 1;
}

.container-set-time {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: block;
}

.pc-body-image {
  width: 100%;
  border-radius: 7px;
  display: inline-block;
}

.camp_cont__cpgc {
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  object-fit: cover;
}

/* Bottom right text */
.time-block-container {
  position: absolute;
  /* top: 20px; */
  left: 10px;
  background-color: black;
  opacity: 0.7;
  border-radius: 8px;
}
.time-block-inner-container {
  /* padding: 0px 8px; */
  padding-bottom: 4px;
  position: relative;
}

.time-block-span {
  font-size: 10px;
  color: white;
  display: inline-block;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.container-set {
  position: relative;
}

.container-set {
  position: relative;
}

.react-player {
  position: relative;
  top: 0px;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
}

.options-div {
  position: absolute;
  right: 10px;
  margin-bottom: 8px;
}

.single-line {
  height: 1px;
  background-color: #e1e3e5;
  width: 100%;
  margin: 0%;
}

.img-div {
  width: 5px;
  height: 5px;
}

.post_icon_on_profile__cpgc {
  position: absolute !important;
  top: 22px;
  left: 22px;
}

.profile_image_cpgc {
  border-radius: 20px;
}

.profile_username__cpgc {
  font-size: 12px;
  font-weight: 500;
}

.profile_followers__cpgc {
  font-size: 11px !important;
  opacity: 1;
  margin-top: 5px;
}

.top_gradiant_overlay_cpgc {
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(38, 42, 48, 0.7) 30%,
    rgba(30, 36, 52, 0) 94%
  );
  opacity: 1;
  transform: rotate(-180deg);
  width: 100%;
  height: 70px;
  border-radius: 8px;
}

.bottom_gradiant_overlay_cpgc {
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(38, 42, 48, 0.7) 30%,
    rgba(30, 36, 52, 0) 94%
  );
  opacity: 1;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  bottom: 0;
}

.card_header_cpgc {
  position: absolute;
  z-index: 2;
  color: white;
}

.post_time__cpgc {
  position: absolute;
  z-index: 2;
  color: white;
  font-size: 12px;
  top: 10px;
  right: 10px;
}

.download_content_btn__cpgc {
  position: absolute;
  z-index: 2;
  bottom: 13px;
  right: 10px;
}

.rights_badges_box {
  position: absolute;
  z-index: 2;
  bottom: 13px;
  left: 10px;
}
