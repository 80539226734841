.container-slider {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  /* transition: opacity ease-in-out 0.4s; */
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.75;
}

.btn-slide img {
  width: 20px;
  height: 20px;
  pointer-events: none;
}
.prev {
  top: 45%;
  left: 10px;
}
.next {
  top: 45%;
  right: 10px;
}

.container-dots {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}

.slider-pc-body-image-new {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.slider-react-player {
  position: relative;
  top: 0px;
  left: 0;
  border-radius: 16px;
  overflow: hidden;
}
