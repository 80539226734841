.container-slider {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  background-color: #000000;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  /* transition: opacity ease-in-out 0.4s; */
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.overlay_btn-slide {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255, 255, 255);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.75;
}

.overlay_btn-slide img {
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.overlay_crousel_control {
  bottom: 18%;
}
.overlay_prev {
  bottom: 18%;
  left: 42%;
}
.overlay_next {
  bottom: 18%;
  right: 42%;
}

.container-dots {
  position: absolute;
  bottom: 21%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}

.slider-pc-body-image-new {
  width: 100%;
  height: 290px;
  object-fit: cover;
}

.slider-media-image-div {
  background-color: #000000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.video-duration-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65px; /* Adjust the size of the play icon */
  height: 28px;
  background-color: white;
  border-radius: 8px;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 5;
}

.video-duration-view:hover {
  cursor: pointer;
}
