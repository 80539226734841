.public-overlay-container {
  width: 100%;
  z-index: 999;
  background-color: #f5ede8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.public-overlay-player {
  position: fixed;
  width: 320px;
  z-index: 2; /* Ensure the overlay is above the main content */
  overflow: hidden;
  height: 568px;
  border-radius: 12px;
  background-color: grey;
}

.react-player {
  position: relative;
  top: 0px;
  left: 0;
  /* border-radius: 5px; */
  overflow: hidden;
}

.play-icon {
  z-index: 10000;
  position: absolute;
  top: 45%;
  left: 45%;
}

.main-content {
  overflow-y: auto; /* Enable vertical scrolling for the main content */
  height: 100%; /* Take up the full height of the container */
  padding: 16px; /* Add padding as needed */
  box-sizing: border-box;
}

.overlay-close-icon {
  position: absolute;
  top: 16px;
  right: 3%;
  width: auto; /* Adjust the size of the play icon */
  height: 21px;
  z-index: 10;
}

.overlay-close-icon:hover {
  cursor: pointer;
}

/* .overlay_post_dotted:hover {
  cursor: pointer;
} */

.new-bottom-sheet {
  height: 100px;
  background-color: white;
}

.overlay_post_video_con_ab__cpgc {
  position: absolute;
  z-index: 2;
  color: white;
  top: 15px;
  left: 10px;
}

.public_overlay_post_time__cpgc {
  position: absolute;
  z-index: 2;
  color: white;
  font-size: 10px;
  top: 13px;
  right: 12px;
}

.overlay_post_dotted_menu {
  position: absolute;
  z-index: 2;
  color: white;
  font-size: 10px;
  top: 26px;
  right: 42px;
  text-align: right;
}

.overlay_post_dotted_menu:hover {
  cursor: pointer;
}

.overlay_post-user-profile-img {
  width: 34px !important;
  height: 34px !important;
  border-radius: 17px !important;
}

.overlay_post-user-profile-img:hover {
  cursor: pointer;
}

.overlay_pc-header-inside {
  padding: 8px 10px;
  line-height: 12px;
  text-align: left;
  height: 45px;
}

.overlay_pc-header-inside-uname {
  font-size: 12px;
  margin-top: 10px;
}

.overlay_pc-header-inside-uname:hover {
  cursor: pointer;
}
.overlay_pc-header-inside-followers {
  font-size: 11px !important;
  color: white !important;
}

.overlay_post_icon_on_profile__cpgc {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
}

.overlay_post_type_icon {
  width: 15px !important;
  height: 15px !important;
}

.overlay_new-usage_rights__pgc {
  position: absolute;
  bottom: 40px !important;
  left: 16px;
  z-index: 10;
  text-align: start;
}

.overlay_usage_rights_text__pgc {
  font-size: 12px !important;
  text-decoration: underline !important;
  color: white !important;
}

.overlay_post_nav_up {
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 10px;
  bottom: 52%;
  right: 5%;
}

.overlay_post_nav_up:hover {
  cursor: pointer;
}

.overlay_post_nav_down {
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 10px;
  bottom: 47%;
  right: 5%;
}

.overlay_post_nav_down:hover {
  cursor: pointer;
}

.overlay-bottom-sheet {
  position: absolute;
  width: 100%;
  height: 390px;
  bottom: 0px;
  z-index: 10;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px;
}

.overlay-sheet-close-icon {
  float: right;
}

.overlay-sheet-close-icon:hover {
  cursor: pointer;
}

.bottomsheet-lbl-des {
  align-items: center;
  height: 100px; /* Set a height for the container (adjust as needed) */
}

.bottom-sheet-command-lbl {
  width: 170px;
  display: inline-block;
  font-size: 10px;
}

/* . {
  position: absolute;
  bottom: 15px;
  left: 16px;
  z-index: 10;
  text-align: start;
} */

.overlay_upgrade_overlay_cpgc {
  position: absolute;
  z-index: 10;
  background: linear-gradient(0deg, #000000 50%, rgb(0, 0, 0, 0.5));
  opacity: 1;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_upgrade_title {
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.overlay_upgrade_description {
  color: white;
  font-size: 12px;
  margin-top: -4px;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
}

.overlay_download_content_btn__cpgc {
  position: absolute;
  z-index: 2;
  bottom: 40px !important;
  right: 10px;
}

.overlay_action-block-inner-container {
  position: relative;
}

.overlay_action-div-btn-right-icon {
  width: 25px;
  height: 25px;
}

.overlay_display_pgc {
  display: flex;
}

.media-image-div {
  background-color: #000000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.new-pc-body-image-new {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.overlay_download-media-div {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 15px;
  /* margin-bottom: 8px; */
}

.overlay_add-collection-div {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 15px;
}

.overlay_download-media-div:hover {
  background-color: #e6e6e7;
  cursor: pointer;
}
.overlay_add-collection-div:hover {
  background-color: #e6e6e7;
  cursor: pointer;
}

.overlay_bottom_post_3dotmenu__cpgc {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 15px;
  z-index: 2;
  font-size: 12px;
  margin-left: -2px;
  padding-top: 2px;
}

.overlay_bottom_post_3dotmenu__cpgc:hover {
  background-color: #e6e6e7;
  cursor: pointer;
}

.overlay-container-set {
  position: absolute;
  margin-top: 5px;
  width: auto;
}

.overlay_container_set {
  position: relative;
  z-index: 1;
  background-color: white;
  border-radius: 8px;
  font-size: 12px;
}

.overlay_collection_set {
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 8px;
}

.overlay_newMenu_container {
  position: absolute;
  z-index: 12;
  background-color: white;
  border-radius: 8px;
}

.tooltip {
  z-index: 100999;
}

.overlay-video-post {
  cursor: pointer; /* Change cursor to pointer for video posts */
}

.overlayplayer_top_gradiant {
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(38, 42, 48, 0.7) 0%,
    rgba(30, 36, 52, 0) 94%
  );
  opacity: 0.7;
  transform: rotate(-180deg);
  width: 100%;
  height: 90px;
  border-radius: 10px;
}

.overlayplayer_bottom_gradiant {
  position: absolute;
  z-index: 1;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 94%);
  opacity: 0.7;
  width: 100%;
  height: 90px;
  border-radius: 7px;
  bottom: 0;
}

.overlaypost-analytics-bottom-sheet {
  position: absolute;
  z-index: 11;
  background-color: white;
  opacity: 1;
  width: 100%;
  height: 200px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  bottom: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 12px;
}

.overlaypost-bts-header {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.overlaypost-bts-close-div {
  text-align: left;
}

.overlaypost-bts-close-div:hover {
  cursor: pointer;
}

.show-overlaypost-analytics-div {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 15px;
}

.show-overlaypost-analytics-div:hover {
  cursor: pointer;
}

.overlaypost-analytics-div {
  margin-inline: 8px;
}
.overlaypost-bts-title-div {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
}

.overlaypost-bts-refresh-div {
  text-align: right;
}

.overlaypost-bts-refresh-div:hover {
  cursor: pointer;
}

.overlaypost-bts-description {
  /* display: flex; */
  align-items: center;
  height: 80%;
}

.overlaypost-bts-description-text {
  font-size: 14px;
  top: 10px;
  padding-top: 24px;
}

.overlaypost-views-div {
  color: white;
  text-align: center;
  display: flex;
  font-size: 10px;
  justify-content: center;
  height: 50px;
}

.overlayplayer_top_loader {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 2%;
}

.overlay_download-media-div:hover {
  background-color: #e6e6e7;
  cursor: pointer;
}

.loading-bar {
  width: 91%;
  height: auto;
  margin-left: 16px;
  cursor: pointer;
  position: relative;
}

.open-original-post-div {
  color: white;
  margin-left: 16px;
  text-decoration: underline;
}

.open-original-post-div:hover {
  cursor: pointer;
}
