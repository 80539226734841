@import "@font/nunito";

.collection_name {
  font-family: var(--fontFamily-nunito);
  font-weight: var(--fontWeight-nunito-regular);
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: start;
  padding-left: 12px;
}

.collection-header {
  background-color: "#1F1F30";
  text-align: "left";
  padding: "15px";
}

.header_center_text__col {
  color: white;
  justify-self: center;
  font-weight: bold;
}
